// api/contact.js

import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL + "/contact" || "apiv1/contact";
// const API_URL = "api/contact";

export default {
  sendContact(payload) {
    return axios.post(API_URL, {
      token: payload.token,
      user_id: payload.user_id,
      subject: payload.subject,
      name: payload.name,
      phone: payload.phone,
      email: payload.email,
      message: payload.message
    });
  }
};
